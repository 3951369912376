import React, { useState } from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import { Animated } from 'react-animated-css'
import ModelVideo from '@/components/ModelVideo'
import play from '@/assets/images/play.png'
import BackIcon from '@/assets/images/back-icon.png'
import Bg1 from '@/assets/images/news/01_bg.jpg'
import Bg1_1 from '@/assets/images/news/01_bg1.png'
import Bg1_2 from '@/assets/images/news/01_bg2.jpeg'

import Bg2 from '@/assets/images/news/02_bg.jpg'
import Bg2_1 from '@/assets/images/news/02_bg1.png'
import Bg2_2 from '@/assets/images/news/02_bg2.png'

import Bg3 from '@/assets/images/news/03_bg.jpg'
import Bg3_1 from '@/assets/images/news/03_bg1.png'
import Bg3_2 from '@/assets/images/news/03_bg2.png'
import Bg3_3 from '@/assets/images/news/03_bg3.png'
import Bg3_4 from '@/assets/images/news/03_bg4.png'

import Bg4 from '@/assets/images/news/04_bg.jpg'
import Bg4_1 from '@/assets/images/news/04_bg1.png'

import Bg5 from '@/assets/images/news/05_bg.jpg'
import Bg5_1 from '@/assets/images/news/05_bg1.png'
import Bg5_2 from '@/assets/images/news/05_bg2.png'
import Bg5_3 from '@/assets/images/news/05_bg3.png'

import Bg6 from '@/assets/images/news/06_bg.jpg'
import Bg6_1 from '@/assets/images/news/06_bg1.png'
import Bg6_2 from '@/assets/images/news/06_bg2.png'
import Bg6_3 from '@/assets/images/news/06_bg3.png'
import Bg6_4 from '@/assets/images/news/06_bg4.png'
import Bg6_5 from '@/assets/images/news/06_bg5.png'
import Bg6_6 from '@/assets/images/news/06_bg6.png'

import Bg7 from '@/assets/images/news/07_bg.jpg'
import Bg7_1 from '@/assets/images/news/07_bg1.png'

import Bg8 from '@/assets/images/news/08_bg.jpg'
import Bg8_1 from '@/assets/images/news/08_bg1.png'
import Bg8_2 from '@/assets/images/news/08_bg2.png'
import Bg8_3 from '@/assets/images/news/08_bg3.png'
import Bg8_4 from '@/assets/images/news/08_bg4.png'
import Bg8_5 from '@/assets/images/news/08_bg5.png'
import Bg8_6 from '@/assets/images/news/08_bg6.png'
import Bg8_7 from '@/assets/images/news/08_bg7.png'
import Bg8_8 from '@/assets/images/news/08_bg8.png'
import Bg8_9 from '@/assets/images/news/08_bg9.png'
import Bg8_10 from '@/assets/images/news/08_bg10.png'
import Bg8_11 from '@/assets/images/news/08_bg11.png'
import Bg8_12 from '@/assets/images/news/08_bg12.png'
import Bg8_13 from '@/assets/images/news/08_bg13.png'

import Bg9 from '@/assets/images/news/09_bg.jpg'
import Bg9_1 from '@/assets/images/news/09_bg1.png'
import Bg9_2 from '@/assets/images/news/09_bg2.png'

import Bg10 from '@/assets/images/news/10_bg.jpg'
import Bg10_1 from '@/assets/images/news/10_bg1.png'
import Bg10_2 from '@/assets/images/news/10_bg2.png'

import Bg11 from '@/assets/images/news/11_bg.jpg'
import Bg11_1 from '@/assets/images/news/11_bg1.png'

const NewsDetail: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [data, setData] = useState({
    cosKey: 'https://lecturermap-1319224539.cos.ap-beijing.myqcloud.com/dev/public/17115312911133400.mp4'
  })
  const navigate = useNavigate()
  const { source, id } = useParams()

  const handlePage = () => {
    if(source === 'home') {
      navigate('/')
    }else {
      navigate('/about/news')
    }
  }

  const handlePlay = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const child = () => {
    if(id == 0) {
      return <Detail01 />
    }else if(id == 1) {
      return <Detail02 />
    }else if(id == 2) {
      return <Detail03 />
    }else if(id == 3) {
      return <Detail04 />
    }else if(id == 4) {
      return <Detail05 />
    }else if(id == 5) {
      return <Detail06 />
    }else if(id == 6) {
      return <Detail07 />
    }else if(id == 7) {
      return <Detail08 />
    }else if(id == 8) {
      return <Detail09 />
    }else if(id == 9) {
      return <Detail10 />
    }else if(id == 10) {
      return <Detail11 />
    }
  }

  return (
    <>
      <main className='header-top'>
        <div className='warp-detail-news home-page'>
          <Animated
            animationIn="bounceInRight"
            animationOut="fadeOut"
            isVisible={true}>
            { child() }
            <div className='go-page' onClick={handlePage}>
              <img src={BackIcon} alt=""/>
              <span>返回上一页</span>
            </div>
          </Animated>
        </div>
      </main>
      <ModelVideo isModalOpen={isModalOpen} handleCancel={handleCancel} data={data} />
    </>
  )
}

export default NewsDetail

const Detail01 = () => {
  return (
    <>
      <div className='detail-news-date'>2024.07.17</div>
      <div className='detail-news-title'>辞旧迎新，未来可7丨熙诚教育投资七周年</div>
      <img className='detail-news-banner' src={Bg1} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          过去7年里，我们种下真诚和专业。 <br />
          此刻，我们收获了朋友和伙伴。 <br />
          熙诚教育投资七周年，我们跟合作伙伴一起庆祝。 <br /> <br />
          <img width='100%' src={Bg1_1} alt=""/>
          图｜熙诚教育投资7周年·战略客户答谢活动
          <br /><br />
          <span className='sub-title'>01、在一起，未来可7</span> <br />
          过去7年，我们跟中国企业一起成长，彼此成就。
          <img width='100%' src={Bg1_2} alt=""/>
          图｜各行业客户齐聚一堂，共话发展 <br /> <br />
          有中国经济发展的压舱石——央企和国企 <br />
          有互联网大厂 <br />
          有传统行业龙头 <br />
          有细分赛道隐形冠军 <br />
          有专精特新的后起之秀 <br />
          …… <br /><br />
          服务过程中，我们力求做到“让每一个客户因我们而精进”，沉淀了诸多有代表性的优秀案例，也更懂中国企业的经营。服务客户的同时，我们也在向客户学习成功的经验，教学相长，熙诚教育投资也因此不断成长和发展。<br />
          过去，我们是向美国、德国、日本等西方国家学习，而今天的很多中国企业，已经走在发展的前沿。我们很难像过去那样，摸着外国企业的经验过河了。去年司庆，我们发起了中国式企业管理典范倡议，希望能扎根于中国企业管理实践，把中国本土内一些具体的、分散的企业管理成果的实践经验不断地条理化、系统化、理论化，构建有中国特色的企业管理知识体系，研究更适合中国企业现状，更适用于当前经济形势的方法。经过了一年的实施与沉淀，我们已经整理汇总了一些具有代表性的“中国式企业管理典范案例”，并将持续努力完成这份事业。 <br />
          教学相长，我们始终跟中国企业在一起。<br /> <br />
          <span className='sub-title'>02、守正创新，未来可7</span> <br />
          再大的企业，再了不起的人物，在某一些时刻都需要思考创新。而现在，大家比过去的任何时刻，都更重视创新，希望通过创新解决新形势下遇到的问题。 <br />
          变局之中，如何创新？ <br />
          我们从很多优秀的企业身上看到了答案：守正创新。 <br />
          行稳智能创始人/CEO翁锋先生，现场就抛出这样一个问题与大家互动： <br />
          “当产品不好卖，老板还要我们交业绩时，我们应该抓什么？” <br />
          翁锋先生的观点是“市场不好的时候，可能得逐渐向管理要业绩”。并通过“过客户”和“半年会定目标”两个常见的场景，表达了自己如何思考和创新。 <br />
          聪明的企业经营者不会盲目的傻创新，守住企业经营管理的本质，是创新的前提。 <br /><br />

          现场，我们通过一个创新剧本杀，跟各位嘉宾一起沉浸式体验，如何解决一个企业经营中最常见的问题：提高组织协同。 <br />
          剧本杀的场景是：企业团建中交通工具发生故障，行政、人资、品牌、业务主管等不同角色如何应对？<br />
          第一轮讨论，每个人都按照自己对角色权责的理解，发表了观点，站在某个角色的立场上。我们发现，人本能地更关注自己，甚至只关注自己。就像在工作场景中，每个部门和岗位，都更优先关注自己的工作内容，这就导致了跨部门沟通和协作中，大家因为立场的不同出现了“部门墙”，甚至淡化了完成这项工作的最终目标。 <br />
          而站在经营者的视角，我们的目标应该是“顺利解决交通工具故障，完成团建”。 <br />
          现场老师做了第二轮引导：强化目标，引导换位思考。 <br />
          各部门开始可以做到目标一致，且“共同看见”，发现问题。内耗减少了，各部门不再是相对独立，大家互溶共进，紧密合作，问题就变得简单了。 <br />
          这其实是企业经营管理中常见的问题：战略目标统一，团队工作协同。我们通过创新的方式提高参与者学习意愿和效果。 <br />
          这也是熙诚教育投资的守正创新。<br />
          守正才能不迷失方向、不犯颠覆性错误，创新才能把握时代、引领时代。<br />
          守正创新，发掘企业内生力量，也许是一切问题的答案。<br /><br />

          <span className='sub-title'>03、自驱进化，未来可7</span><br />

          “多数曾经成功的路径在当前新经济环境下或许不再是最优解，甚至当下的选择会冲击我们过去的决策流程。”<br />
          —— 熙诚教育投资总经理  李越<br />
          今天，中国企业面临的挑战是什么？<br />
          1、企业的绩效可能是由外部因素决定的；<br />
          2、企业自身过去的经验可能不再可复用；<br />
          3、持续的不确定性。<br />

          面对不确定性挑战，企业必须辞旧迎新，不断进化。<br />

          “要解决问题，必须通过计划、组织、领导、协调和控制，最大限度地发挥资源的使用效率，实现资源组合的效率最大化。想要管理更有效，决策效率更高，必须要理清楚组织内部所有个体之间的关系，清楚界定责、权、利，通过职责、制度、流程等方面的设计，定义资源的分配和流转路径，从而实现组织效率的最大化。于是，我们研发了一个咨询项目——帕累托价值再造。”<br /><br />

          越总所讲的咨询项目，是2023年我们萃取真实的企业成功经验，为了协助企业正确理解数字化、结合企业实际的业务场景，从人才与组织的角度做好转型准备，为企业数字化转型提供有力、可落地的抓手，推出了面向数字化转型困境的解决方案《达尔文人才跃迁计划》。同时，继提升组织内“人”发现问题的能力、改善的动力、持续成长的内驱力后，2024年，光合企学再度聚焦“流程”这一重要环节，研发了【帕累托价值再造】咨询项目，通过梳理企业价值创造的流程，对齐各部门工作价值，帮助企业找到隐性增长点，将资源聚向创造价值。<br />
          商业唯一不变的，就是永远处于变局之中。“黑天鹅”和“灰犀牛”事件随着社会、科技、经济、政治环境的发展不断上演。企业告别对过去的路径依赖，聚焦人才和组织的不断进化才能生存和发展。<br />
          活动的最后，李越对在场的客户再次表示诚挚的感谢。我们将继续秉持教学相长的初心，植根于企业学习领域，不断创新和优化。<br />
          我们将跟中国优秀企业一起，坐在世界经济发展的“牌桌上”，展望未来。<br />
        </div>
        {/*<div className='detail-news-video'>*/}
        {/*  <div className='detail-news-mask flex-center' onClick={handlePlay}>*/}
        {/*    <img src={play} alt=""/>*/}
        {/*  </div>*/}
        {/*  <img width='100%' src='https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/cdd8098e8e4743279cf712526594d98f_mergeImage.png'></img>*/}
        {/*</div>*/}
      </div>
    </>
  )
}

const Detail02 = () => {
  return (
    <>
      <div className='detail-news-date'>2024.01.08</div>
      <div className='detail-news-title'>熙诚教育投资积极投身教育公益事业，协办春禾第七期校长研修坊</div>
      <img className='detail-news-banner' src={Bg2} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          1月3日至5日，由上海春禾青少年发展中心主办、熙诚教育投资协办的春禾第七期校长研修坊在北京开展，来自全国各地的春禾项目学校校长30余人参加了研修活动。 <br />
          本期校长研修坊，包括“系统思考领导力”、“校长5E领导力”等多项主题内容，目的是帮助各位校长开阔教育视野、更新教育理念，提升校长领导力，切实落地研究性学习课程，带领学校教育回归培养学生核心素养的教育本真。 <br />
          <img width='100%' src={Bg2_1} alt=""/>
          图｜熙诚教育投资捐赠了“系统思考”相关课程
          <br /><br />
          春禾理事长陆逊重点感谢了熙诚教育投资对本期活动的捐赠：除了为大家提供免费的活动场地及课程服务外，熙诚教育投资还捐赠了“系统思考领导力”主题课程，为教育工作者进行系统性思考的定向能力培养，帮助各位校长以全局视角理解和解决复杂问题，有效面对学校管理中的挑战和困难。随后，陆逊理事长代表春禾，聘请熙诚教育投资总经理李越担任上海春禾青少年发展中心名誉理事。 <br />
          <img width='100%' src={Bg2_2} alt=""/>
          图｜春禾聘请李越担任上海春禾青少年发展中心名誉理事 <br /> <br />
          ‘传递前沿思想’，一直以来都是熙诚教育投资在践行的使命。然而前沿思想的传递是有过程的，在不同节点上，不同的个体和组织也发挥着不同的作用。在此过程中，学校作为一线，先将先进思想传递到‘最前端’，而我们，就扮演着“桥梁”角色，让走出校园迈入企业的人获得更好地成长，承载更多先进的管理理念，最后一起完成人才培养的全生命周期这个伟大事业中去。熙诚教育投资作为一家国有企业，有责任、有担当加入这个事业中去，这也是我们和春禾合作的初衷。”李越表示，接下来，我们会加强与上海春禾青少年发展中心在教育公益领域协作，共同推动核心素养教育的发展，助力乡村教育振兴，构建合作共赢新格局。
        </div>
      </div>
    </>
  )
}

const Detail03 = () => {
  return (
    <>
      <div className='detail-news-date'>2023.12.08</div>
      <div className='detail-news-title'>熙诚教育投资携手北大国发院开展承泽企业家圆桌会，探寻中国品牌成长之路</div>
      <img className='detail-news-banner' src={Bg3} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          2023年，应时代的新变局和企业家的新需求，北大国发院正式推出了【承泽企业家研修班】，重点培养企业家在新变局时代急需的新思维、新能力，助力其开拓事业的新局面，通达人生的新境界。<br />
          为了促进承泽班学员与校友互相融合，构建企业家经验与智慧交流的平台，11月17日，北大国发院承泽企业家研修班开启了第一期圆桌会活动——“探寻中国品牌成长之路”。此次活动由熙诚教育投资作为协办单位提供了执行协助。 <br />
          <img width='100%' src={Bg3_1} alt=""/>
          图｜探寻中国品牌成长之路·大合影
          <br /><br />
          本次活动，经过深度了解案例主企业的背景和需求，熙诚教育投资的教练团队打破了以往私董会仅有企业家互相输入的场景设计，引入业务专家和行业内头部企业的企业家作为补充，提供其它公司遇到同样问题的解法与经验。这种方式一方面保证了案例主企业能获取有效的建议，另一方面建立“借假修真”的模式，将一个具体问题泛化为企业共性的业务发展问题，让每一个参与者都能有更深度的收获，同时也让每一位参与者都见贤思齐，彼此贡献智慧与力量。<br /><br />
          本期私董会案例企业是由承泽企业家学员陈萍和万娟女士共同创立的高端品牌——Thaiv。Thaiv珠宝定位于高净值人群，展现的是珍珠之美。如何把珍珠之美，转变成商业价值，并不断发扬光大是两位案主希望大家探讨的问题。<br /><br />
          活动中，案例主介绍了本企业的发展概况、在经营管理中遇到的主要问题，以及希望通过本次圆桌会活动希望解决的问题。来自不同行业、不同性质企业的听众在专家教练的引导下，通过两轮提问，不断抽丝剥茧，探索品牌价值和核心定位，讨论如何获取及培育核心客户，寻找珠宝企业的国际化路径。在这个过程中案例主明确了自己的3个核心问题，现场企业家们围绕精准客户画像，拓展新客群和复购粘性培育这三个维度，分成3个小组，在组织教练、营销专家以及现场同行业专家的带领下，共同形成了针对案例主企业的有效落地解决方案。<br />
          <img width='100%' src={Bg3_2} alt=""/>
          <img width='100%' src={Bg3_3} alt=""/>
          图｜各小组分别探讨解决方案 <br /> <br />
          在此次活动策划中，熙诚教育投资邀请的专家教练，成为了案例主和听众的“催化剂”，他不断建议案例主以一颗开放的心去聆听所有的建议、意见，同时也引导听众去关注企业的特质以及最关键的诉求。<br /> <br />

          活动结束后，在场的企业家分享了对于此次活动的一些感悟：<br />
          “今天我的收获：了解了一个高端品牌故事，私人定制的匠心品质和长期主义的价值坚持；同时也认识了一批优秀创业者。企业家研修项目各项活动的精雕细琢，体现了国发院精品项目的价值。” <br /><br />
          “整个圆桌会围着主题有序进行，让我看到了一些大家共性的思考模式也看到了很多非常珍贵的视角，整个过程都非常启发深度思考。” <br />
          <img width='100%' src={Bg3_4} alt=""/>
          图｜Thaiv对活动方表示感谢
          <br /><br />
          作为此次活动的协办方，熙诚教育投资未来将继续推进与北京大学国家发展研究院的深度合作，持续为企业家提供解决真实业务问题的解决方案，助力中国式企业管理典范走向世界。
        </div>
      </div>
    </>
  )
}

const Detail04 = () => {
  return (
    <>
      <div className='detail-news-date'>2023.12.05</div>
      <div className='detail-news-title'>荣誉时刻丨熙诚教育投资荣获第15届博奥奖“年度最佳数字化人才赋能服务商”</div>
      <img className='detail-news-banner' src={Bg4} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          上个月，由在线教育资讯网（Online-edu）主办的第15届中国企业数智化学习大会（CEFE）在北京隆重举行，大会结合当下热门的人工智能、数字人、元宇宙以及AIGC等前沿技术在培训场景下的创新与实践话题开展。其中最受瞩目的“博奥奖”颁奖典礼上，熙诚教育投资凭借专业化的课程设计能力和精益求精的创新态度获得“年度最佳数字化人才赋能服务商”。 <br />
          <img width='500px' src={Bg4_1} alt=""/> <br />
          图｜熙诚教育投资荣获博奥奖·年度最佳数字化人才赋能服务商
          <br /><br />
          博奥奖作为“数字化学习行业”的重量级奖项，自2008年首届发起至今，已经累计数千家企业和机构参与申报评选，共沉淀数千个数字化学习及应用的实践案例，评选出的获奖项目对当时的企业培训提供了丰富的思路和可行性方案，为行业的健康发展起到了重要的推动作用。<br /><br />
          熙诚教育投资作为中国咨询培训行业的国企先锋，关注企业人才发展的各个阶段，为企业人才成长路径进行全面赋能。“光合企学”是熙诚教育投资旗下企业学习品牌，在2023年推出了数字化解决方案，从数智洞察、数智解码、数智组织、数智引擎四个模块，为企业培养数字化人才，助力企业数字化转型有效落地。此次获得“年度最佳数字化人才赋能服务商”奖项，充分证明培训行业对光合企学数字化解决方案的高度认可。<br /><br />
          未来，光合企学将继续深耕企业学习领域，以丰富的行业经验沉淀和追求卓越的匠心精神，不断提升产品创新力，持续巩固产品服务实力，做企业成长道路上最值得信赖的伙伴，为客户的健康可持续发展注入强劲能量！
        </div>
      </div>
    </>
  )
}

const Detail05 = () => {
  return (
    <>
      <div className='detail-news-date'>2023.10.25</div>
      <div className='detail-news-title'>成都高新区国资金融局一行莅临熙诚教育投资座谈交流</div>
      <img className='detail-news-banner' src={Bg5} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          10月24日，成都高新区国资金融局党组书记、局长彭涌一行莅临熙诚教育投资指导工作，双方就“资本赋能，共促企业高质量发展”主题进行工作座谈。北京观韬中茂律师事务所、四川桃子健康科技股份有限公司、申万宏源证券承销保荐有限责任公司等领导及相关同志也参加本次座谈。 <br />
          <img width='100%' src={Bg5_1} alt=""/> <br />
          图｜与高新区金融局领导一行进行交流
          <br /><br />
          熙诚教育投资总经理李越对此次到访的成都高新区国资金融局领导、企业代表、金融机构代表表示热烈欢迎，对成都高新区国资金融局对熙诚教育投资的关注表示感谢，并简要介绍了熙诚教育投资发展概况与产业布局。他表示，熙诚教育投资在区域产业规划布局与升级、大中型企业高质量发展与全域生态建设、中小企业持续增长资本赋能等方面，与成都高新区支柱产业拥有广阔合作空间，希望双方能够加强沟通、彼此赋能寻求更多合作机会，共同护航区域企业高质量发展，从容走向资本市场。<br /><br />
          <img width='100%' src={Bg5_2} alt=""/> <br />
          图｜双方交流如何帮助企业走向资本市场
          <br /><br />
          彭涌对熙诚教育投资的热忱接待表示感谢，并介绍了高新区产业发展概况。他表示，本次到访熙诚教育投资，旨在加强双方在各自优势领域的学习，寻求全新合作机会。希望未来双方能够建立联络机制，加强沟通合作，打通资源链路，助力中小企业发展，支持更多企业挂牌融资，开创高新区发展新格局。<br />
          <img width='100%' src={Bg5_3} alt=""/> <br />
          图｜彭涌分享高新区企业发展现状
          <br /><br />
          与会的企业代表及金融机构也积极参与座谈交流，就企业融资、政策扶持、区域产业规划与发展等内容展开讨论。参与座谈的所有嘉宾一致期望，未来能借助区域合作，对各项资源进行重点对接，聚焦互补与高效合作，共同助推高质量发展，实现地方经济社会发展与企业成长壮大的双赢局面。
        </div>
      </div>
    </>
  )
}

const Detail06 = () => {
  return (
    <>
      <div className='detail-news-date'>2023.09.15</div>
      <div className='detail-news-title'>产品为王丨光合企学亮相2023企业教育培训博览会！</div>
      <img className='detail-news-banner' src={Bg6} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          <img width='100%' src={Bg6_1} alt=""/> <br /><br />
          9月14日至9月15日，新华报业传媒集团《培训》杂志发起的“2023企业教育培训博览会”在深圳大中华喜来登酒店隆重举行。<br /><br />
          光合企学作为企培行业的优秀代表，也受邀参加了此次活动，并与到场的国内外大咖、专业领域专家学者以及优秀企业代表，共同碰撞新思路，交流新实践，探索新发展，共同构建高效对接、精准匹配的资源平台和共生共赢的产业生态，推动中国企业教育培训事业高质量发展。<br /><br />

          <span className='sub-title'>聚焦“人才进阶、组织建设、业务发展”</span><br />
          <span className='sub-title'>——为企业提供定制化解决方案</span><br /><br />

          企业的核心是产品。同样，行业做大做强的核心依然是产品。在当下这个时代，优秀的培训产品，是验证企业培训服务商真正实力的“硬通货”。<br /><br />
          作为《培训》杂志的长期合作伙伴，光合企学亮相2023企业教育培训博览会展区，聚焦企业人才进阶、组织建设、业务发展等内容，重点展示了系统思考、战略解码、高潜培养、数字化解决方案、演进1号位等核心产品，进一步彰显了光合企学为企业提供定制化解决方案的能力。
          <img width='100%' src={Bg6_2} alt=""/> <br /><br />
          受到展示产品的吸引，诸多参会同仁纷纷驻足，与现场工作人员进行沟通洽谈！<br />
          <img width='100%' src={Bg6_3} alt=""/>
          <img width='100%' src={Bg6_4} alt=""/>
          <span className='sub-title'>【高潜人才打造计划】为企业嵌入增长基因</span> <br /><br />
          产业变迁、技术革新与跨界融合正在重塑组织与人的关系，企业的商业模式、组织模式和领导模式也在发生着深刻的变革。面对复杂多变的经济环境，企业的组织和领导力需要更快速、更高效、更灵活地应对和解决来自于企业内外复杂多变的新问题。<br />
          在领导力与组织变革论坛上，熙诚教育投资副总经理张笑妮，以高潜人才打造为例，发表主题演讲，与在场嘉宾深度探讨领导力发展与组织发展领域的创新实践和发展成果，助力中国企业组织与领导力创新成长。<br />
          <img width='100%' src={Bg6_5} alt=""/>
          <img width='100%' src={Bg6_6} alt=""/>
          图｜张笑妮发表高潜人才打造主题演讲 <br /><br />
          “组织应该主动识别和培养所需要的人才，而不是被动地应对人才短缺的情况。” <br /><br />
          据调研，61%的企业领导者认为打造组织的关键人才是首要任务，72%的企业面临高管团队青黄不接的现状。张笑妮认为，不确定时代下，高管人才供应将成为企业永续经营的首要任务。设计高潜人才培养项目时，首先应根据公司战略要求明确高潜人才发展标准，识别可发展人才进入高潜人才池子；其次，根据高潜人才培养标准制定培养计划，紧贴高潜发展路径进行逐层深入辅导提升；最后进行关键评估，建立完善的高潜评估机制与高潜追踪体系。<br />
          光合企学【高潜人才打造计划】更精准、更科学、更高效，从高潜识别到发展演进，再到关键评估，紧密结合企业发展特点，让人才发展潜能转化成绩效，为企业嵌入增长基因，让改变真正发生。 <br />
        </div>
      </div>
    </>
  )
}

const Detail07 = () => {
  return (
    <>
      <div className='detail-news-date'>2023.07.31</div>
      <div className='detail-news-title'>为进一步深化战略合作，熙诚教育投资一行赴雄安新区调研</div>
      <img className='detail-news-banner' src={Bg7} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          在广袤的冀中平原，一幅新时代美好的雄安画卷正快速铺展。今天的雄安，正处在如火如荼的建设进行时，并且轮廓已经越来越清晰。<br /><br />
          7月28日，熙诚教育投资总经理李越、公共事务负责人熊思远一行赴雄安新区调研，就进一步深化战略合作，赋能中小企业高质量发展等内容，与雄安新区管委会相关领导进行深度交流。<br /><br />
          <img width='100%' src={Bg7_1} alt=""/>
          图｜熙诚教育投资一行赴雄安新区调研
          <br /><br />
          当前，世界正处于百年未有之大变局，经济持续承压，对中小企业发展和管理都提出了新的挑战和要求。此时，引领中小企业发展，加速形成优质中小企业铺天盖地的发展格局也是雄安新区义不容辞的责任与使命。<br /><br />
          在2022年，新区先后出台了《关于促进“专精特新”企业和数字经济核心产业高质量发展的若干措施》《关于推进规上工业企业培育发展的四条措施》等一系列政策，助力新区企业的高质量发展。<br /><br />
          下一步，新区将紧盯推动中小企业又好又快发展这一目标，加快构建新区中小企业的“321”工作体系，持续激发中小企业活力。<br /><br />
          调研期间，熙诚教育投资一行详细了解雄安新区整体规划及建设情况，表示将充分发挥自身在企业培训行业优势，为进一步加快雄安新区中小企业发展，提振中小企业发展士气，激发企业发展动能，打造一支高素质人才队伍，实现产业高质量发展贡献一份力量。
         </div>
      </div>
    </>
  )
}

const Detail08 = () => {
  return (
    <>
      <div className='detail-news-date'>2023.07.21</div>
      <div className='detail-news-title'>有为·可为——熙诚教育投资六周年庆圆满落幕</div>
      <img className='detail-news-banner' src={Bg8} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          <img width='100%' src={Bg8_1} alt=""/> <br />
          向未来，有所为；启新幕，可为之！<br />
          2023年7月20日，“有为·可为——熙诚教育投资六周年庆典”在北京圆满举行。<br /><br /><br />
          <span className='sub-title'>共赴山海篇</span><br /><br />
          作为企业培训行业的国企先锋，六年来，熙诚教育投资始终坚持以实干笃定前行，聚焦中国企业成长，深耕产品与服务，持续突破创新，谋求企业与客户、员工、师资、社会多方的共创共赢，实现了从开创者到引领者的跃迁蜕变，不断打造中国式企业管理新范式。
          <br /><br />
          回顾六年的发展历程，熙诚教育投资在充满不确定性的市场环境中，不断探索与校准，立足于整个行业发展现状，积极开展多元化业务，并借助卓越的资源整合能力和高质量培训服务能力，深度参与到企业的业务和管理中，逐步形成企业学习与咨询培训业务齐头并进、企业人才梯队建设与企业家培养相辅相成、国央企服务和市场化运营互动的事业格局，并在上海、深圳、成都设立了办事处，在全国范围内积极进行业务扩张。此外，熙诚教育投资在保持企业稳健发展的同时，对企业文化进行升级，诠释企业在新时代下的责任与担当，为企业健康发展创造有效路径。
          <br /><br />
          “为世界打造中国式企业管理典范，这个愿景既是熙诚教育投资企业发展的目标，也是我们事业的定位。中国企业飞速发展四十多年，有很多优秀的本土化实践成果。中国企业家在企业管理的过程中，一边立足中国国情，汲取中国传统文化经验与智慧，一边又与时俱进，广泛学习和借鉴外国企业管理的科学理论和实践，不断深化、丰富和创新。这些企业实践的产物，如明珠一般散落各地。所以我们想要打造出中国式企业管理典范，扎根中国企业管理实践，把这些具体的、分散的企业管理成果的实践经验不断地条理化、系统化、理论化，构建出中国自主的企业管理知识体系，助推中国企业健康持续发展。”
          <br /><br />
          庆典现场，熙诚教育投资总经理李越先生发起中国式企业管理典范倡议，并邀请在场嘉宾及社会更多志同道合者参与进来，不断修正对中国式企业管理的理解和定义，构建有中国特色的企业管理知识体系，让中国企业扎根的这片沃土释放出更多的生机与能量。
          <br /><br />
          <img width='100%' src={Bg8_2} alt=""/>
          图｜打造中国式企业管理典范·合作伙伴合影 <br /><br />
          本次活动邀请了来自全国各地多位企业代表、各业界翘楚、业内专家名师参会，各界人士对熙诚教育投资的倡议表示非常认同及支持，纷纷表示愿意为这项伟大的事业贡献力量，并对熙诚教育投资送来了美好的祝福。<br /><br />
          在以中国式现代化全面推进中华民族伟大复兴的新征程上，企业面临艰巨繁重的发展任务和千头万绪的管理课题，熙诚教育投资将以6周年为新契机，在公司发展战略的指引下，以稳健的姿态迎难而上，为广大客户提供更优质的产品与服务，并用乘风破浪的豪情、大有可为创佳绩的激情，做企业成长道路上最值得信赖的伙伴，朝着“为世界打造中国式企业管理典范”这一长期目标奋勇前进！<br /><br />

          <span className='sub-title'>员工欢庆篇</span><br />
          活动当天，全体员工齐聚丽泽SOHO办公区，开启了一场别出心裁的六周年欢快旅程。<br />
          <img width='100%' src={Bg8_3} alt=""/>
          图｜大家在卡通墙互动区，为自己绘制可爱头像 <br />
          <img width='100%' src={Bg8_4} alt=""/>
          <img width='100%' src={Bg8_5} alt=""/>
          图｜大家纷纷与自己的可爱头像合影 <br />
          <img width='100%' src={Bg8_6} alt=""/>
          图｜品牌公关部熊思远为大家介绍企业IP形象-诚端端<br />
          <img width='100%' src={Bg8_7} alt=""/>
          图｜员工展示自己的司庆大礼包，诚端端玩偶深受大家喜欢 <br />
          <img width='100%' src={Bg8_8} alt=""/>
          图｜“诚端端的日常”表情包，在企业微信群里开启了一波刷屏 <br />
          <img width='100%' src={Bg8_9} alt=""/>
          <img width='100%' src={Bg8_10} alt=""/>
          <img width='100%' src={Bg8_11} alt=""/>
          图｜大家高效配合协作，拼出大有可为的熙诚教育投资<br />
          <img width='100%' src={Bg8_12} alt=""/>
          <img width='100%' src={Bg8_13} alt=""/>
          大家把对一年后的自己想说的话，放进专属“时间胶囊”里，期待一年之后，再次站在时间的路口，与现在的自己一起奔赴可期的未来！
        </div>
      </div>
    </>
  )
}

const Detail09 = () => {
  return (
    <>
      <div className='detail-news-date'>2023.06.05</div>
      <div className='detail-news-title'>深化合作丨雄安管委会领导莅临熙诚教育投资指导交流</div>
      <img className='detail-news-banner' src={Bg9} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          2023年6月2日，雄安管委会领导莅临熙诚教育投资指导工作，双方就企业发展话题进行深度交流。 <br />
          <img width='100%' src={Bg9_1} alt=""/>
          图｜雄安管委会领导莅临指导
          <br /><br />
          今天的雄安新区，经过6年规划建设，已进入大规模建设与承接北京非首都功能疏解并重阶段，工作重心已转向高质量建设、高水平管理、高质量疏解发展并举。 <br /><br />
          据了解，当前中央企业在雄安新区设立各类机构140多家，其中二三级子公司90多家；雄安新区“雄才计划”已引进院士及其他高端领军人才12名，选录招聘“双一流”高校人才3000余名，新增各类创新创业人才2.5万余名。此时，如何让新人才在雄安生根、发芽，如何更好地为入驻企业高质量发展提供助力，成为雄安新区未来重点研究的一个课题。针对此项课题，熙诚教育投资与雄安管委会进行了深度探讨，并围绕“人才管理”和“企业高质量发展”形成了初步成果。<br /><br />
          熙诚教育投资作为中国咨询培训行业的国企先锋，旗下拥有的“光合企学”、“演进1号位”和“知识管理局”三大业务品牌，为区域产业规划布局与升级、大中型企业高质量发展与全域生态建设、中小企业持续增长等，提供系统化解决方案与资源整合支持。尤其是聚焦中小企业健康可持续发展的“演进1号位”，重点关注中小企业高层次人才培训与发展，聚合资源打造培、产、融三位一体服务机制，推动咨询培训专业服务的体系化建设，促进中国中小企业现代化治理体系建设。<br /><br />
          <img width='100%' src={Bg9_2} alt=""/>
          图｜雄安管委会领导参观办公区域并合影 <br /> <br />
          雄安管委会领导参观了熙诚教育投资办公区，对我司企业发展、文化建设等方面有了进一步了解。双方还就企业治理、产业项目服务、产融对接等其它课题进行了深入交流。
        </div>
      </div>
    </>
  )
}


const Detail10 = () => {
  return (
    <>
      <div className='detail-news-date'>2024.09.26</div>
      <div className='detail-news-title'>荣誉时刻|熙诚教育投资荣获第七届年度经济大会“2024中国（行业）创新品牌100强”奖项</div>
      <img className='detail-news-banner'  src={Bg10} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          凝心聚力同奋进，群贤毕至谋发展，9月26日，2024（第七届）年度经济大会在北京隆重举行。大会以“新质生产力引领经济高质量发展”为主题，吸引了来自政府、企业、学术界等众多领域的精英代表共襄盛举。北京熙诚教育投资有限公司作为企业咨询培训领域的国企先锋，受邀出席了此次盛会，并获得“2024中国（行业）创新品牌100强”荣誉奖项。 <br />
          <img width={'100%'} src={Bg10_1} alt=""/> <br /><br />
          大会现场，政商产学研各界嘉宾齐聚一堂，聚焦发展前沿，解读政策趋势，交流创新思考，共绘发展图景，携手共进不断开辟高质量发展新领域、新赛道，为全面推进中国式现代化建设贡献智慧与力量。熙诚教育投资近几年在产品开发、市场拓展和品牌建设等方面取得的显著成就，赢得了与会嘉宾的高度评价。并且凭借其在企业咨询培训领域的卓越表现，荣获了大会颁发的“2024中国（行业）创新品牌100强”奖项。该荣誉不仅是对熙诚教育投资过去所获成绩的肯定，更是对未来发展的期许与鼓励。
          <br />
          <img  width={'100%'} src={Bg10_2} alt=""/> <br />
          此次出席2024（第七届）年度经济大会，熙诚教育投资再次向业界展现了其行业影响力和品牌实力。公司表示，将以此次大会为契机，进一步加强与各界的交流与合作，共同推动经济高质量发展。 <br /><br />
          展望未来，熙诚教育投资将继续以市场需求为导向，以产品创新为驱动，不断提升企业核心竞争力和品牌影响力。同时，公司还将积极践行国有企业的责任和担当，向“新”发力，锚定方向，以“质”致远，在推进中国式现代化新征程上，朝着“为世界打造中国式企业管理典范”这一长期目标不动摇，厚植新质生产力的生长土壤，为中国经济的高质量发展贡献一份力量。
        </div>
      </div>
    </>
  )
}


const Detail11 = () => {
  return (
    <>
      <div className='detail-news-date'>2024.09.10</div>
      <div className='detail-news-title'>荣誉时刻|熙诚教育投资荣获第三届T20培训咨询行业企业家论坛“最佳合作潜力奖</div>
      <img className='detail-news-banner' src={Bg11} />
      <div className='detail-news-block'>
        <div className='detail-news-desc'>
          9月9日至９月10日，由T20培训机构董事长俱乐部主办的“创新破局·逆势增长：弘扬企业家精神 聚焦高质量发展” ——2024第三届T20培训咨询行业企业家论坛暨结构思考力10周年生态合作大会在北京成功举办。北京熙诚教育投资有限公司受邀参与论坛并荣获【最佳合作潜力奖】。 <br />
          <img src={Bg11_1} alt=""/><br />
          图｜熙诚教育投资荣获【最佳合作潜力奖】 <br /><br />
          本次论坛专注揭示“破局创新、逆势增长”的底层逻辑，熙诚教育投资与来自各个机构、单位的专家、学者、企业家创始人、行业领军人物协同共进，深度交流，积极探索培训咨询机构利润增长新路径，为推动中国培训咨询行业高质量发展贡献一份力量。 <br /><br />
          在变革与机遇并存的2024年，企业培训，始终是件难而正确的事，愈是艰难时期，愈发考验组织能力。面对市场层层挑战，熙诚教育投资从未停止前进的脚步，不断打磨升级产品能力，在领导力培养、系统思考、高潜人才培养、财务团队赋能、企业数字化转型解决方案等企业学习细分领域重点发力，开发多款自研产品，并打造讲师地图，不断提升企业竞争力。 <br /><br />
          我们通过培训与咨询服务相结合的方式，目前已经服务了300多家企业，沉淀了诸多有代表性的优秀客户案例，也逐渐做到了“让每一个客户因我们而精进”。我们的客户主要集中在金融、科技、医药等行业，其中包括多家知名企业，如：字节跳动、腾讯、阿里、百度、中国结算、中信建投、国家开发银行、泰康养老、国家电投、北京银行、华润双鹤、农夫山泉、一汽大众、中建集团等，累计服务超过20000名企业学员。<br /><br />
          随着AI技术的不断迭代升级，培训行业必然将会迎来更大的发展机遇。熙诚教育投资将坚持行业化深耕，与各行业组织深度共创，快速升级迭代、革新产品能力，锻造智能、高效、敏捷、专业的“产品力”，赋能客户业务快速发展，帮助客户解决实打实的经营问题，助力中国企业高质量发展。
        </div>
      </div>
    </>
  )
}